import Vimeo from "@u-wave/react-vimeo";
import React, { useState, useEffect } from "react";
import PortfolioPagesHeader from "./PortfolioPagesHeader";
import PortfolioPagesFooter from "./PortfolioPagesFooter";
import "./StudentFilmPage.css";

const windowMinSize = 700;

const StudentFilmPage = (props) => {
  const [isExpanded, setIsExpanded] = useState(
    window.innerWidth >= windowMinSize
  );

  const isWindowExpanded = () => {
    setIsExpanded(window.innerWidth >= windowMinSize);
    return isExpanded;
  };

  useEffect(() => {
    window.addEventListener("resize", isWindowExpanded);
  }, []);

  return (
    <div className="student-film-page">
      <PortfolioPagesHeader />
      <div className="student-film-page-text-container">
        <div className="student-film-page-header">
          <h1>TERATOPHOBIA</h1>
        </div>
        <div className="student-film-page-subheader">
          <h1>NOUN</h1>
        </div>
        <div className="student-film-page-subheader2">
          <h1>Psychiatry: Fear of giving birth to a monster</h1>
        </div>
        <div className="student-film-page-blurb">
          <h2>
            My first short film, and also my undergrad student thesis film.
            Written, directed, and produced by myself. I also did all the
            special FX and editing for this film.
          </h2>
        </div>
        <div className="vimeo-embed">{/* <Vimeo video="196508683" /> */}</div>
        {isExpanded ? (
          <Vimeo video="196508683" />
        ) : (
          <a href="https://vimeo.com/196508683">
            <div className="vimeo-btn-container">
              <img src="/vimeo_logo_blue_on_white.png" className="vimeo-btn" />
            </div>
          </a>
        )}
      </div>
      <PortfolioPagesFooter prev={props.prev} next={props.next} />
    </div>
  );
};

export default StudentFilmPage;
