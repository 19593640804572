import React from "react";
import "./PortfolioSplashPage.css";
import PortfolioCard from "./PortfolioCard";

export const PortfolioPages = {
  FEATURES: {
    text: "Screen Rant Reality TV Features Writer",
    link: "features",
    thumbnail: "tv.jpg",
  },
  CLIENTS: {
    text: "Current And Previous Clients",
    link: "clients",
    thumbnail: "clients.jpg",
  },
  BLOGS: {
    text: "Ferndate Blog Posts",
    link: "blogs",
    thumbnail: "hearts.jpg",
  },
  STUDENT_FILM: {
    text: "Teratophobia - Short Film",
    link: "studentFilm",
    thumbnail: "movie.jpg",
  },
  SELF_PUBLISHED: {
    text: "Blog Posts - Self Published",
    link: "selfPublished",
    thumbnail: "blog-thumbnail.jpg",
  },
  OFF_BINARY: {
    text: "OffBinary Articles",
    link: "offBinary",
    thumbnail: "offbinary.jpg",
  },
};

const PortfolioSplashPage = () => {
  return (
    <div className="portfolio-splash-page">
      <div className="portfolio-splash-page-header">
        <h1>
          My diverse range of creative work and experience is an amazing asset
          to everything I do. This portfolio is a collection of all my published
          and produced work.
        </h1>
      </div>
      <div className="portfolio-splash-page-cards">
        <div className="portfolio-splash-page-card">
          <PortfolioCard content={PortfolioPages.OFF_BINARY} />
        </div>
        <div className="portfolio-splash-page-card">
          <PortfolioCard content={PortfolioPages.FEATURES} />
        </div>
        <div className="portfolio-splash-page-card">
          <PortfolioCard content={PortfolioPages.CLIENTS} />
        </div>
        <div className="portfolio-splash-page-card">
          <PortfolioCard content={PortfolioPages.BLOGS} />
        </div>
        <div className="portfolio-splash-page-card">
          <PortfolioCard content={PortfolioPages.STUDENT_FILM} />
        </div>
        <div className="portfolio-splash-page-card">
          <PortfolioCard content={PortfolioPages.SELF_PUBLISHED} />
        </div>
      </div>
    </div>
  );
};

export default PortfolioSplashPage;
