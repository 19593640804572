import React from "react";
import "./OffBinaryPage.css";
import image from "./offbinary.jpg";
import PortfolioPagesHeader from "./PortfolioPagesHeader";
import PortfolioPagesFooter from "./PortfolioPagesFooter";

const OffBinaryPage = (props) => {
  return (
    <div className="off-binary-page">
      <PortfolioPagesHeader />
      <div className="off-binary-page-header">
        <img src={image} alt="offBinary" className="off-binary-page-image" />
        <h1>OffBinary Articles</h1>
      </div>
      <div className="off-binary-page-image-container"></div>
      <div className="off-binary-page-link-header">
        <h1>LINKS</h1>
      </div>
      <div className="off-binary-page-links">
        <a href="https://offbinary.com/blogs/articles/masculine-makeup-how-to-look-more-masculine-using-makeup?_pos=2&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>Masculine Makeup: How to Look More Masculine Using Makeup</h2>
          </div>
        </a>
        <a href="https://offbinary.com/blogs/articles/plus-size-clothing-a-gender-free-style-guide?_pos=3&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>Plus Size Clothing: A Gender-Free Style Guide</h2>
          </div>
        </a>
        <a href="https://offbinary.com/blogs/articles/masculine-clothing-a-gender-inclusive-guide?_pos=4&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>Masculine Clothing: A Gender-inclusive Guide</h2>
          </div>
        </a>
        <a href="https://offbinary.com/blogs/articles/guide-to-packing-for-gender-affirmation-and-exploration?_pos=5&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>Guide to Packing For Gender Affirmation and Exploration</h2>
          </div>
        </a>
        <a href="https://offbinary.com/blogs/articles/chest-binder-the-ultimate-guide?_pos=6&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>Chest Binders: Everything You Need to Know About Binding</h2>
          </div>
        </a>
        <a href="https://offbinary.com/blogs/articles/trans-tape-the-ultimate-guide?_pos=7&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>Trans Tape: The Ultimate Guide</h2>
          </div>
        </a>
        <a href="https://offbinary.com/blogs/articles/masculine-hair-a-gender-inclusive-guide?_pos=8&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>Masculine Hair: A Gender-inclusive Guide</h2>
          </div>
        </a>
        <a href="https://offbinary.com/blogs/articles/how-to-look-more-masculine-gender-inclusive-guide?_pos=9&_sid=71e6822f8&_ss=r">
          <div className="off-binary-page-link">
            <h2>How to Look More Masculine - Gender Inclusive Guide</h2>
          </div>
        </a>
      </div>
      <PortfolioPagesFooter prev={props.prev} next={props.next} />
    </div>
  );
};

export default OffBinaryPage;
